import React from "react";
import "./index.css";
import { IoSettingsSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoQrCodeSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";

function AccountInfoDropdown() {
  return (
    <div className="userinfo-dropdown-options">
      <div className="d-flex align-items-center justify-content-between pb-3 acc__header">
        <p className="account-type">Free Account</p>
        <Link to={"/go-pro"} className="upgrade-btn">Upgrade</Link>
      </div>
      <div>
        <Link to={"/settings"} className="setting-nav-container">
          <div className="setting-option text-center d-flex align-items-center justify-content-center">
            <IoSettingsSharp color="#fff" size={20} />
          </div>
          <div className="setting-option-heading">
            <p className="setting-option-heading-1">Settings</p>
            <p className="setting-option-heading-2">Update Dashboard</p>
          </div>
        </Link>
        <Link to={"/qr-codes"} className="setting-nav-container">
          <div className="setting-option text-center d-flex align-items-center justify-content-center nav-calendar">
            <IoQrCodeSharp color="#fff" size={20} />
          </div>
          <div className="setting-option-heading">
            <p className="setting-option-heading-1">QR Code Update</p>
            <p className="setting-option-heading-2">Update the QR question </p>
          </div>
        </Link>
        <Link to={"/settings"} className="setting-nav-container">
          <div className="setting-option text-center d-flex align-items-center justify-content-center nav-profile">
            <FaUser color="#fff" size={20} />
          </div>
          <div className="setting-option-heading">
            <p className="setting-option-heading-1">Profile</p>
            <p className="setting-option-heading-2">Update your profile</p>
          </div>
        </Link>
      </div>
      <div></div>
      <div></div>
    </div>
  );
}

export default AccountInfoDropdown;
