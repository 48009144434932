// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.account-container{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.submit-btn-conatiner{
    margin-top: 20px;
}

.save-btn-styles{
    border-radius: 5px;
    height: 47px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormComponent/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,yCAAyC;AAC7C;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".app-form{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.account-container{\n    display: flex !important;\n    align-items: center !important;\n    justify-content: space-between !important;\n}\n.submit-btn-conatiner{\n    margin-top: 20px;\n}\n\n.save-btn-styles{\n    border-radius: 5px;\n    height: 47px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
