// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pagination {
  margin-top: 1rem;
  margin-left: 10px;
  max-width: 1300px;
}

.pagve-nav-icon{
    font-size: 14px;
    margin-bottom: 3px;
    color: #202224;
    opacity: 0.6;
}

.page-summary {
  color: #202224;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;
  padding: 0;
  margin: 0;
}

.page-nav{
    background-color: #fff;
    padding-inline: 10px;
    border-radius: 5px;
    border: 0.6px solid #979797;
}

.page-nav.left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-nav.right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-nav-container{
    margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,YAAY;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;IACI,sBAAsB;IACtB,oBAAoB;IACpB,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".app-pagination {\n  margin-top: 1rem;\n  margin-left: 10px;\n  max-width: 1300px;\n}\n\n.pagve-nav-icon{\n    font-size: 14px;\n    margin-bottom: 3px;\n    color: #202224;\n    opacity: 0.6;\n}\n\n.page-summary {\n  color: #202224;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  opacity: 0.6;\n  padding: 0;\n  margin: 0;\n}\n\n.page-nav{\n    background-color: #fff;\n    padding-inline: 10px;\n    border-radius: 5px;\n    border: 0.6px solid #979797;\n}\n\n.page-nav.left{\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n}\n\n.page-nav.right{\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n\n.page-nav-container{\n    margin-right: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
