// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.href-a{
    text-decoration: none;
    margin: 0;
    padding: 0;
    color: #fff;
}

.app-error-header{
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000
}

.app-error-header-logo{
    width: 150px;
    height: 50px;
    object-fit: contain;
}

.error-page-body{
    margin-top: 70px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}

.broken-link-img{
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.report-btn-add-class{
    width: 200px !important;
    margin-inline: 10px;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/modules/ErrorBoundary/index.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iDAAiD;IACjD,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR;AACJ;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf","sourcesContent":["\n.href-a{\n    text-decoration: none;\n    margin: 0;\n    padding: 0;\n    color: #fff;\n}\n\n.app-error-header{\n    padding: 1rem;\n    background-color: #fff;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    z-index: 2000\n}\n\n.app-error-header-logo{\n    width: 150px;\n    height: 50px;\n    object-fit: contain;\n}\n\n.error-page-body{\n    margin-top: 70px;\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 60vh;\n}\n\n.broken-link-img{\n    width: 300px;\n    height: 300px;\n    object-fit: contain;\n}\n\n.report-btn-add-class{\n    width: 200px !important;\n    margin-inline: 10px;\n    margin-bottom: 2rem;\n    margin-top: 1rem;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
