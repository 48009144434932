// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-mask {
  background: rgba(13, 13, 13, 0.2) !important;
  -webkit-backdrop-filter: blur(2px) !important;
          backdrop-filter: blur(2px) !important;
}

.ant-modal-content {
  border-radius: 15px !important;
  background: #fff !important;
}


.app-modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30000;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-modal-content{
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
}

@media (max-width: 576px) {
  .col-sm-11 {
      width: 91.66666667%;
  }
}

/* .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Modal/index.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,6CAAqC;UAArC,qCAAqC;AACvC;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B;;;AAGA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,cAAc;EACd,WAAW;EACX,YAAY;EACZ,cAAc;EACd,8BAA8B;EAC9B,kCAA0B;UAA1B,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE;MACI,mBAAmB;EACvB;AACF;;AAEA;;;;;;;;;;;GAWG","sourcesContent":[".ant-modal-mask {\n  background: rgba(13, 13, 13, 0.2) !important;\n  backdrop-filter: blur(2px) !important;\n}\n\n.ant-modal-content {\n  border-radius: 15px !important;\n  background: #fff !important;\n}\n\n\n.app-modal{\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 30000;\n  width: 100%; \n  height: 100%; \n  overflow: auto; \n  background: rgba(0, 0, 0, 0.5);\n  backdrop-filter: blur(3px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.app-modal-content{\n  background-color: #fefefe;\n  margin: auto;\n  padding: 20px;\n  border: 1px solid #888;\n  border-radius: 20px;\n}\n\n@media (max-width: 576px) {\n  .col-sm-11 {\n      width: 91.66666667%;\n  }\n}\n\n/* .overlay {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  backdrop-filter: blur(3px);\n  z-index: 1;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
