// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loader-portal {
  position: relative;
}

.loader-portal-container {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.5); */
  /* backdrop-filter: blur(0.5px); */
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE;;;;;;;;;yCASuC;EACvC,kCAAkC;AACpC","sourcesContent":["#loader-portal {\n  position: relative;\n}\n\n.loader-portal-container {\n  /* position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999999999;\n  background-color: rgba(0, 0, 0, 0.5); */\n  /* backdrop-filter: blur(0.5px); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
