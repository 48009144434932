// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  border-radius: 19px;
  border: 0.6px solid #d5d5d5;
  background-color: #f5f6fa;
  min-width: 30%;
  overflow: hidden;
  padding: 6px;
}

.search-input {
  outline: none;
  border: none;
  background-color: #f5f6fa;
  margin-inline: 10px;
  color: #202224;
  font-size: 14px;
  font-weight: 400;
}

.header__account-conatiner {
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
}

.header__account-conatiner > div > p {
  padding: 0;
  margin: 0;
}

#profile-pic {
  object-fit: contain;
  width: 45px;
  height: 45px;
  border: 1px solid rgba(45, 35, 35, 0.7);
  border-radius: 50%;
  margin-right: 10px;
}

#username {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#role {
  color: rgba(45, 35, 35, 0.5);
  font-size: 9px;
  font-weight: 800;
}

.account-dropdown-container{
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}

.userinfo-dropdown-options{
  display: none;
}

.account-dropdown-container:hover .userinfo-dropdown-options{
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Search/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".search-container {\n  border-radius: 19px;\n  border: 0.6px solid #d5d5d5;\n  background-color: #f5f6fa;\n  min-width: 30%;\n  overflow: hidden;\n  padding: 6px;\n}\n\n.search-input {\n  outline: none;\n  border: none;\n  background-color: #f5f6fa;\n  margin-inline: 10px;\n  color: #202224;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.header__account-conatiner {\n  display: flex;\n  align-items: center;\n  margin-right: 1.25rem;\n}\n\n.header__account-conatiner > div > p {\n  padding: 0;\n  margin: 0;\n}\n\n#profile-pic {\n  object-fit: contain;\n  width: 45px;\n  height: 45px;\n  border: 1px solid rgba(45, 35, 35, 0.7);\n  border-radius: 50%;\n  margin-right: 10px;\n}\n\n#username {\n  color: #404040;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n#role {\n  color: rgba(45, 35, 35, 0.5);\n  font-size: 9px;\n  font-weight: 800;\n}\n\n.account-dropdown-container{\n  cursor: pointer;\n  position: relative;\n  margin-right: 1rem;\n}\n\n.userinfo-dropdown-options{\n  display: none;\n}\n\n.account-dropdown-container:hover .userinfo-dropdown-options{\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
