// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tooltip styling */
.Tooltip_tooltip__lKrqH {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    overflow: visible;
}

.Tooltip_tooltip__lKrqH .Tooltip_tooltiptext__TVW89 {
    visibility: hidden;
    width: 250px;
    background-color: #fff;
    color: #000000b3;
    text-align: center;
    border: 1px solid #d5d5d566;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: -1px;
    margin-left: 0px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    opacity: 0;
    transition: opacity 0.3s;
}

.Tooltip_tooltip__lKrqH:hover .Tooltip_tooltiptext__TVW89 {
    visibility: visible;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,8FAA8F;IAC9F,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":["/* Tooltip styling */\n.tooltip {\n    display: inline-block;\n    position: absolute;\n    cursor: pointer;\n    overflow: visible;\n}\n\n.tooltip .tooltiptext {\n    visibility: hidden;\n    width: 250px;\n    background-color: #fff;\n    color: #000000b3;\n    text-align: center;\n    border: 1px solid #d5d5d566;\n    border-radius: 6px;\n    padding: 5px;\n    position: absolute;\n    z-index: 1;\n    bottom: 15px;\n    left: -1px;\n    margin-left: 0px;\n    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);\n    opacity: 0;\n    transition: opacity 0.3s;\n}\n\n.tooltip:hover .tooltiptext {\n    visibility: visible;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `Tooltip_tooltip__lKrqH`,
	"tooltiptext": `Tooltip_tooltiptext__TVW89`
};
export default ___CSS_LOADER_EXPORT___;
