import React from "react";
import PropTypes from "prop-types";
import "./index.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function Page({
  total = 0,
  currentPage = 1,
  next = () => {},
  prev = () => {},
  defaultPageSize = 10,
  auto = false,
}) {
  const getSummary = () => {
    let from = (currentPage - 1) * 10 + 1;
    let to = currentPage * 10;
    return `${String(from).padStart(2, "0")}-${String(Math.min(to, total)).padStart(2, "0")} of ${String(total).padStart(2, "0")}`;
  };

  if (total <= 0) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-between app-pagination">
      <p className="page-summary">Showing {getSummary()}</p>
      <div className="d-flex page-nav-container">
        <button className="page-nav right">
          <IoIosArrowBack className="pagve-nav-icon"></IoIosArrowBack>
        </button>
        <div
          className="page-nav d-flex align-items-center justiy-content-center "
          style={{ fontSize: 13, borderRadius: 0, fontWeight: "700", color:"rgba(61, 189, 255, 1)" }}
        >
          {currentPage}
        </div>
        <button className="page-nav left">
          <IoIosArrowForward className="pagve-nav-icon"></IoIosArrowForward>
        </button>
      </div>
    </div>
  );
}

Page.propTypes = {
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  auto: PropTypes.bool,
};

export default Page;
